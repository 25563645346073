import React, { useState, useEffect } from "react";
import axios from "axios";
import { navigate } from "@reach/router";

const Main = () => {
  const [loaded, setLoaded] = useState(false);
  const [pirates, setPirates] = useState([]);

  const getPirates = () => {
    axios
      .get("/api/pirate")
      .then((res) => {
        console.log(res.data);
        setPirates(res.data);
      })
      .then(setLoaded(true))
      .catch((err) => console.log(err));
  };

  const deletePirate = (pirateID) => {
    axios
      .delete("/api/pirate/delete/" + pirateID)
      .then((res) => {});
    removeFromDom(pirateID);
  };

  const removeFromDom = (pirateID) => {
    setPirates(pirates.filter((pirate) => pirate._id !== pirateID));
  };

  const sortedPirates = [...pirates].sort((a, b) =>
    a.name.localeCompare(b.name)
  );

  useEffect(() => {
    getPirates();
  }, [loaded]);
  return (
    <div className="container">
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          gap: "1.5em",
          border: "1px solid black",
        }}
      >
        <h3>Pirate Crew</h3>
        <button
          className="btn btn-primary btn-sm"
          onClick={() => navigate("/pirate/new")}
        >
          Add Pirate
        </button>
      </div>

      {sortedPirates.map((pirate) => {
        return (
          <>
            <div style={{ display: "flex", border: "1px solid black" }}>
              <img src={pirate.imgURL} alt={pirate.name} width="200px" />
              <div style={{ alignSelf: "center" }}>
                <h4>{pirate.name}</h4>
                <button
                  className="btn btn-primary btn-sm"
                  onClick={() => navigate(`/pirate/${pirate._id}`)}
                >
                  View Pirate
                </button>
                <button
                  className="btn btn-danger btn-sm"
                  onClick={() => deletePirate(pirate._id)}
                >
                  Walk the Plank
                </button>
              </div>
            </div>
          </>
        );
      })}
    </div>
  );
};

export default Main;
