import React, { useState } from "react";
import { navigate } from "@reach/router";
import axios from "axios";

const PirateForm = () => {
  const initialFormState = {
    name: "",
    imgURL: "",
    treasures: "",
    catchPhrase: "",
    crewPosition: "",
    pegLeg: true,
    eyePatch: true,
    hookHand: true,
  };
  const [pirateForm, setPirateForm] = useState(initialFormState);
  const [error, setError] = useState({});

  const validateInput = (input) => {
    if (input.length === 0) {
      return false;
    }
    return true;
  };

  const onChangeHandler = (e) => {
    setPirateForm({
      ...pirateForm,
      [e.target.name]: e.target.value,
    });
    if (e.target.name === "pegLeg") {
      setPirateForm({ ...pirateForm, [e.target.name]: e.target.checked });
    }
    if (e.target.name === "eyePatch") {
      setPirateForm({ ...pirateForm, [e.target.name]: e.target.checked });
    }
    if (e.target.name === "hookHand") {
      setPirateForm({ ...pirateForm, [e.target.name]: e.target.checked });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post("/api/pirate/create", pirateForm)
      .then((res) => {
        if (res.data.err) {
          console.log("There was an error");
          setError(res.data.err.errors);
        } else {
          console.log("a product was created");
          navigate("/pirates");
        }
      })
      .catch((err) =>
        console.log("Something went wrong with the post request")
      );
  };
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          gap: "1.5em",
          border: "1px solid black",
        }}
      >
        <h1>Add Pirate</h1>
        <button
          className="btn btn-primary btn-sm"
          onClick={() => navigate("/pirates")}
        >
          Crew Board
        </button>
      </div>
      <div>
        <form
          onSubmit={handleSubmit}
          style={{ width: "25%", margin: "0 auto" }}
        >
          <div className="input-group input-group-sm mb-3">
            <span className="input-group-text">Name</span>
            <input
              type="text"
              className="form-control"
              name="name"
              onChange={onChangeHandler}
            />
            {validateInput(pirateForm.name) ? "" : <p>Name is required</p>}
          </div>
          {error.name ? (
            <span className="text-danger">{error.name.message}</span>
          ) : (
            ""
          )}
          <div className="input-group input-group-sm mb-3">
            <span className="input-group-text">Image URL</span>
            <input
              type="text"
              className="form-control"
              name="imgURL"
              onChange={onChangeHandler}
            />
            {validateInput(pirateForm.imgURL) ? "" : <p>Image is required</p>}
          </div>
          {error.imgURL ? (
            <span className="text-danger">{error.imgURL.message}</span>
          ) : (
            ""
          )}
          <div className="input-group input-group-sm mb-3">
            <span className="input-group-text"># of Treasure Chests</span>
            <input type="number" name="treasures" onChange={onChangeHandler} />

            {validateInput(pirateForm.treasures) ? (
              ""
            ) : (
              <p>Select option from treasure chest</p>
            )}
          </div>
          {error.treasures ? (
            <span className="text-danger">{error.treasures.message}</span>
          ) : (
            ""
          )}
          <div className="input-group input-group-sm mb-3">
            <span className="input-group-text">Catch Phrase</span>
            <input
              type="text"
              className="form-control"
              name="catchPhrase"
              onChange={onChangeHandler}
            />
            {validateInput(pirateForm.catchPhrase) ? (
              ""
            ) : (
              <p>A catch phrase is required</p>
            )}
          </div>
          {error.catchPhrase ? (
            <span className="text-danger">{error.catchPhrase.message}</span>
          ) : (
            ""
          )}
          <div className="input-group input-group-sm mb-3">
            <span className="input-group-text">Crew Position</span>
            <select name="crewPosition" onChange={onChangeHandler}>
              <option selected hidden value=""></option>
              <option value="captain">Captain</option>
              <option value="firstMate">First Mate</option>
              <option value="quarterMaster">Quarter Master</option>
              <option value="boatswain">Boatswain</option>
              <option value="powderMonkey">Powder Monkey</option>
            </select>
            {validateInput(pirateForm.crewPosition) ? (
              ""
            ) : (
              <p>Crew position is required</p>
            )}
          </div>
          {error.crewPosition ? (
            <span className="text-danger">{error.crewPosition.message}</span>
          ) : (
            ""
          )}
          <div className="input-group input-group-sm mb-3">
            <span className="input-group-text">Peg Leg</span>
            <input
              checked={pirateForm.pegLeg}
              type="checkbox"
              name="pegLeg"
              onChange={onChangeHandler}
            />
          </div>
          {error.pegLeg ? (
            <span className="text-danger">{error.pegLeg.message}</span>
          ) : (
            ""
          )}
          <div className="input-group input-group-sm mb-3">
            <span className="input-group-text">Eye Patch</span>
            <input
              checked={pirateForm.eyePatch}
              type="checkbox"
              name="eyePatch"
              onChange={onChangeHandler}
            />
          </div>
          {error.eyePatch ? (
            <span className="text-danger">{error.eyePatch.message}</span>
          ) : (
            ""
          )}
          <div className="input-group input-group-sm mb-3">
            <span className="input-group-text">Hook Hand</span>
            <input
              checked={pirateForm.hookHand}
              type="checkbox"
              name="hookHand"
              onChange={onChangeHandler}
            />
          </div>
          {error.hookHand ? (
            <span className="text-danger">{error.hookHand.message}</span>
          ) : (
            ""
          )}
          <button
            disabled={
              pirateForm.name === "" ||
              pirateForm.imgURL === "" ||
              pirateForm.treasures === ""
            }
            type="submit"
            className="btn btn-primary w-100 my-3"
          >
            Submit
          </button>
        </form>
      </div>
    </>
  );
};

export default PirateForm;
