import React, { useEffect, useState } from "react";
import axios from "axios";

const Detail = (props) => {
  const [pirate, setPirate] = useState([]);
  useEffect(() => {
    axios
      .get(`/api/pirate/${props._id}`)
      .then((res) => setPirate(res.data))
      .then(console.log(pirate))
      .catch((err) => console.log(err));
  }, []);

  return (
    <>
      {pirate ? (
        <>
          <div>
            <h1>{pirate.name}</h1>
          </div>
          <div style={{ display: "flex" }}>
            <div>
              <img src={pirate.imgURL} alt={pirate.name} />
              <p>{pirate.catchPhrase}</p>
            </div>
            <div>
              <h3>About</h3>
              <p>Position: {pirate.crewPosition}</p>
              <p>Treasures: {pirate.treasures}</p>
              <p>Peg Leg: {pirate.pegLeg ? <span>Yes</span> : "No"}</p>
              <p>Eye Patch: {pirate.eyePatch ? <span>Yes</span> : "No"}</p>
              <p>Hook Hand: {pirate.hookHand ? <span>Yes</span> : "No"}</p>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default Detail;
