import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Router } from "@reach/router";
import Main from "./views/Main";
import Detail from "./views/Detail";
import PirateForm from "./components/PirateForm";

function App() {
  return (
    <div className="App">
      <Router>
        <Main path="/pirates" />
        <PirateForm path="/pirate/new" />
        <Detail path="/pirate/:_id" />
      </Router>
    </div>
  );
}

export default App;
